$primary: #0089ff;
$secondary: #0065ef;

$dark: #001717;
$dark2: #383838;
$danger: #C11574;
$success: #027A48;
$warning: #E9B911;

.primary {
    color: $primary !important;
}

.border-primary {
    border: 1px solid $primary !important;
}

.secondary {
    color: $secondary !important;
}

.danger {
    color: $danger !important;
}

.success {
    color: $success !important;
}

.warning {
    color: $warning !important;
}


.bg-primary {
    background-color: $primary !important;
}

.bg-dark {
    background-color: $dark !important;
}

.bg-dark2 {
    background-color: $dark2 !important;
}

.border-primary {
    border: 1px solid $primary !important;
}

.bg-primary-light {
    background-color: rgba($primary, .06) !important;
}
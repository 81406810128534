@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');

@font-face {
    font-family: "Millik";
    src: local("Millik"), url('./Millik-Regular.otf') format('opentype');
}

.millik {
    font-family: 'Millik' !important;
}

body,
html {
    line-height: 1.5;
    font-size: 13.7px;
    font-family: "Libre Franklin", sans-serif !important;
    color: #101828 !important;
    font-weight: 400;
}

.small {
    font-size: 12px;
}

h1,
h2,
h3,
h4 {
    font-weight: bold !important;
}

.pointer {
    cursor: pointer;
}

.box-icon {
    text-align: center;
}

.container {
    height: calc(100vh - 32px);
    width: calc(100vw - 16px - 250px);
    margin: 16px 16px 16px 0px;
    border-radius: 20px !important;
    background-color: #F5F5F5;
    overflow-y: scroll;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.border-spacing-2 {
    --tw-border-spacing-x: 0px !important;
}

.AuthMobileTop {
    height: 32px;
    background-image: url(../img/AuthMobileTop.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

#header_mobile,
.sider-overlay {
    display: none;
}

#businessScroll {
    display: block;
}

.settlement_account {
    padding: 16px 20px;
    background: #E6F6F6;
    border-left: 4px solid $primary;
    border-radius: 8px;
}

.canvas {
    width: 100% !important;
    height: auto !important;
}

.transi {
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}

.material-symbols-outlined.fill {
    font-variation-settings:
        'FILL' 1,
        'wght' 400,
        'GRAD' 0,
        'opsz' 24
}

.pop {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;

    &:hover {
        -webkit-transform: scale(1.04);
        transform: scale(1.04);
    }
}

// * {
//     outline: 1px solid red;
// }

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.bg-img {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 20vh;
}

ul,
ol {
    list-style: disc !important;
    padding-left: 15px;
}

.imp-td:nth-of-type(even) {
    background-color: #f4f6fd;
}